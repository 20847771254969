import { ChevronRightIcon, EditIcon, AlertIcon } from '@swe/shared/ui-kit/components/icon';
import SectionHeading from '@swe/shared/ui-kit/components/section-heading';
import Stack from '@swe/shared/ui-kit/components/stack';

import { useCallback } from 'react';

import { isStateOf } from '@swe/shop-ui/entities/shop/info';
import { useCurrentUser } from 'common/providers/user';
import { useRouterNavigate } from 'common/router';
import { Routes } from 'common/router/constants';
import { useStoreInfo } from 'common/use-cases/use-store-info';

const PrivacyAndSecurity = () => {
  const navigate = useRouterNavigate();
  const store = useStoreInfo();
  const { user } = useCurrentUser();

  const isCalifornia = !!store && isStateOf(store, 'California');

  const toPersonalInformationForm = useCallback(() => navigate(Routes.ProfilePersonalInformation), [navigate]);
  const toPasswordChange = useCallback(() => navigate(Routes.ProfilePasswordChange), [navigate]);

  return (
    <Stack
      divider
      spacing="none"
    >
      {isCalifornia && (
        <SectionHeading
          size="lg"
          bullet={AlertIcon}
          trailIcon={ChevronRightIcon}
          onClick={toPersonalInformationForm}
        >
          Do Not Sell My Personal Information
        </SectionHeading>
      )}
      {!user?.hasPassword && (
        <SectionHeading
          size="lg"
          bullet={EditIcon}
          trailIcon={ChevronRightIcon}
          onClick={toPasswordChange}
        >
          Change Password
        </SectionHeading>
      )}
    </Stack>
  );
};

export { PrivacyAndSecurity };
